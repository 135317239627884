import "./App.css";
import NavBar from "./components/NavBar";

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/HomePage/Home";
import Footer from "./components/pages/Footer/Footer";
import Music from "./components/pages/Music/Music";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/music" exact Component={Music} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
