export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Welcome",
  headline:
    "Thank you for entering the world of CooMBaaH!! Wonderful music inspired by Sierra Leone ",
  description: `CooMBaaH formally known as Triple C is a Sierra Leonean artist based in the United Kingdom with strong ties to Germany. Born in Freetown, Sierra Leone, he moved to Berlin, Germany at a very early age where he completed his education and moved to Newcastle United Kingdom to study music. 
  After completing his studies, he moved to London to pursue his musical career. 
    `,
  buttonLabel: "Booking Enquiry",
  endlink: "/bookings",
  imgStart: "",
  img: "images/CooMBaaH.jpg",
  alt: "Credit Card",
  redirect: false,
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Previous Release",
  headline: "Hard 4 Me",
  description:
    "This project is produced by Twano Cherry (DJ TC) in Berlin Germany, its written and performed by popular Reggae and R&B singer Denham Smith and CooMBaaH",
  buttonLabel: "Listen Now",
  endlink: "https://www.youtube.com/watch?v=Ia81gnvZSI0",
  imgStart: "",
  img: "images/Hard-4-Me-Single-Cover.jpeg",
  alt: "Vault",
  redirect: true,
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "OUT NOW",
  headline: "Motherland",
  description:
    "This project is inspired by the song Sina Makosa by Les Wanyika, its a collaboration with Frederik Nnaji (Nnaji). The song is produced by CooMBaaH and Dalton Roberts. It is written and performed by CooMBaaH and Nnaji.",
  buttonLabel: "Listen Now",
  endlink: "https://www.youtube.com/watch?v=0aZRJVhtCKQ",
  imgStart: "start",
  img: "images/MOTHERLAND square4.jpg",
  alt: "Vault",
  redirect: true,
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Fan Items",
  headline: "Grab your favorite item from the FanZone ",
  description: "Get the CooMBaaH merch here.",
  buttonLabel: "Fan Zone",
  endlink: "/Fanzone",
  imgStart: "start",
  img: "images/temp1.jpeg",
  alt: "Vault",
  redirect: false,
};
